export const articles =[
    { 
      FractionId: 1, 
      Fraction: "Fracción I", 
      Title: "Registro público de los servidores", 
      Area: "Unidad de Asuntos Jurídicos (Analista Juridico)", 
      Update: "14/12/2023", 
      Period: "Mensual",
      Description: "El registro público de los servidores públicos, particulares, personas físicas o morales, públicas o privadas, sancionados por resolución definitiva firme, a través del procedimiento para el fincamiento de responsabilidades resarcitorias.",  
      Icon: "98I-VII_blanco80.png",
      Files:[]
    },
    { 
      FractionId: 2, 
      Fraction: "Fracción II", 
      Title: "Manuales de organización y procedimientos", 
      Area: "Unidad General de Administración (Jefatura de Capital Humano y Profesionalización)", 
      Update: "14/12/2023", 
      Period: "Mensual",
      Description: "El registro público de los servidores públicos, particulares, personas físicas o morales, públicas o privadas, sancionados por resolución definitiva firme, a través del procedimiento para el fincamiento de responsabilidades resarcitorias.",  
      Icon: "98I-VII_blanco80.png",
      Files:[]
    },
    { 
      FractionId: 3, 
      Fraction: "Fracción III", 
      Title: "Acuerdos en los cuales se deleguen facultades", 
      Area: "Unidad de Asuntos Jurídicos (Analista Juridico)", 
      Update: "14/12/2023", 
      Period: "Mensual",
      Description: "El registro público de los servidores públicos, particulares, personas físicas o morales, públicas o privadas, sancionados por resolución definitiva firme, a través del procedimiento para el fincamiento de responsabilidades resarcitorias.",  
      Icon: "98I-VII_blanco80.png",
      Files:[]
    },
    { 
      FractionId: 4, 
      Fraction: "Fracción IV", 
      Title: "Estatuto que regule el servicio profesional ", 
      Area: "Unidad de Asuntos Jurídicos (Analista Juridico)", 
      Update: "14/12/2023", 
      Period: "Mensual",
      Description: "El estatuto que regule el servicio profesional de carrera de la auditoría.",  
      Icon: "98I-VII_blanco80.png",
      Files:[]
    },
    { 
      FractionId: 5, 
      Fraction: "Fracción V", 
      Title: "Informes de resultados", 
      Area: "Unidad de Asuntos Jurídicos (Analista Juridico)", 
      Update: "20/12/2023", 
      Period: "Mensual",
      Description: "Los informes de resultados de las observaciones, recomendaciones y acciones promovidas y, en su caso, de la imposición de las multas respectivas, y demás acciones que deriven de los resultados de las auditorías practicadas.",  
      Icon: "98I-VII_blanco80.png",
      Files:[]
    },
    { 
      FractionId: 6, 
      Fraction: "Fracción VI", 
      Title: "Informes entregados al Congreso del Estado", 
      Area: "Unidad de Asuntos Jurídicos (Analista Juridico)", 
      Update: "28/12/2023", 
      Period: "Mensual",
      Description: "Los informes entregados al Congreso del Estado del estado que guardan la solventación de las observaciones, recomendaciones y acciones promovidas a las entidades fiscalizadas, con motivo de la fiscalización de la Cuenta Pública.",  
      Icon: "98I-VII_blanco80.png",
      Files:[]
    },
    { 
      FractionId: 7, 
      Fraction: "Fracción VI", 
      Title: "Informes del estado de trámite de las acciones", 
      Area: "Unidad de Asuntos Jurídicos (Analista Juridico)", 
      Update: "28/02/2023", 
      Period: "Anual (en su caso, 30 días naturales una vez generada la información)",
      Description: "Los informes entregados al Congreso del Estado del estado que guardan la solventación de las observaciones, recomendaciones y acciones promovidas a las entidades fiscalizadas, con motivo de la fiscalización de la Cuenta Pública.",  
      Icon: "98I-VII_blanco80.png",
      Files:[]
    },
  ];