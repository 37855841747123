export const articles =[
    { 
      FractionId: 1, 
      Fraction: "Fracción I", 
      Title: "Solicitar a los sujetos obligados", 
      Area: "Unidad General de Administración (Dirección de Informática)", 
      Update: "23/03/2021", 
      Period: "Anual",
      Description: "Solicitar a los sujetos obligados que, atendiendo a los lineamientos emitidos por el Sistema Nacional, remitan el listado de información que consideren de interés público.",  
      Icon: "106I_blanco80.png",
      Files:[{Name: "Información de interés público", Path: "106_I_Anual2021.xlsx"}]
    }
  ];