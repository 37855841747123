import React from 'react';
import { Outlet } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const MainLayout = () => {
  return (<>
    <Header />
    <Navbar />
      <main>
        <div className="parent">
          <Image className="main-img" src="./assets/img/pantone/logo_grises.png" />
        </div>
        <Outlet />
      </main>
    <Footer />
    </>
  );
};

export default MainLayout;
