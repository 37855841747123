import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Proteccion = () => {

  return (<>

    <Container className='pt-4 pb-4 fadeIn'>
      <Row><Col className='text-center pb-3 article-title'><h1>Protección de Datos Personales</h1></Col></Row>


      <Row>
        <Col className='pb-3'>
        <table width="100%" border="0">
    <tbody><tr>
      <td><p><strong>1. LEY       GENERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE SUJETOS       OBLIGADOS y LEY DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE SUJETOS       OBLIGADOS DEL ESTADO DE NUEVO LEÓN </strong>(Artículos 43 y 52, respectivamente)
        </p>
        <p>En todo momento el titular o su representante podrán solicitar al  responsable, el acceso, rectificación, cancelación u oposición al  tratamiento de los datos personales que le conciernen, de conformidad con lo  establecido en el presente Título. El ejercicio de cualquiera de los derechos  ARCO no es requisito previo, ni impide el ejercicio de otro. </p>
        <p>&nbsp;</p>
        <p><strong>2. POLÍTICA DE PROTECCIÓN       DE DATOS PERSONALES DE LA AUDITORÍA SUPERIOR DEL ESTADO DE NUEVO LEÓN </strong>(Artículos       32 a 35)</p>
<p>Para  efectos de este procedimiento, se considera que los Derechos ARCO comprenden:</p>
<p>I. Acceso:       Derecho del titular para acceder a sus datos personales en posesión de la       ASENL, así como conocer la información relacionada con las condiciones y       generalidades de su tratamiento.</p>
<p>II. Rectificación:       Derecho del titular para solicitar a la ASENL la corrección de sus datos       personales, cuando estos resulten inexactos, incompletos o no se       encuentren actualizados.</p>
<p>III. Cancelación:       Derecho del titular para solicitar a la ASENL que sus datos personales       sean bloqueados y, posteriormente, suprimidos de los archivos, registros,       expedientes y sistemas institucionales de la ASENL, a fin de que los       mismos no se encuentren más en su posesión y, por lo tanto, dejen de ser       tratados.</p>
<p>IV. Oposición:       Derecho del titular para solicitar a la ASENL que se abstenga de utilizar       información personal para ciertos fines o de requerir que se concluya su       uso, a fin de evitar un daño o afectación a su persona.</p>
<p><u> MEDIOS DISPONIBLES  PARA LA RECEPCIÓN DE SOLICITUDES DE EJERCICIO DE LOS DERECHOS ARCO</u></p>
<p>La presentación de  solicitudes de Derechos ARCO podrá realizarse a través de los medios de  recepción siguientes:</p>
<p>I. Unidad de Transparencia       de la ASENL: ubicada en calle Loma Larga 2550, colonia Obispado,       Monterrey, Nuevo León. C.P. 64060</p>
<p>II. Correo electrónico: <a href="mailto:accesoainformacion@asenl.gob.mx">accesoainformacion@asenl.gob.mx</a></p>
<p>III. Plataforma Nacional de       Transparencia: <a href="http://www.plataformadetransparencia.org.mx/" rel="noreferrer" target="_blank">http://www.plataformadetransparencia.org.mx/</a></p>
<p>La Unidad de Transparencia  será la responsable de turnar las solicitudes de ejercicio de Derechos ARCO que  sean presentadas a la ASENL a aquellas UA que conforme a sus atribuciones,  competencias o funciones puedan o deban poseer los datos personales se  pronuncien, para dar atención a estas en los plazos y términos establecidos en  la Ley General, la Ley Local, los Lineamientos Generales, Lineamientos Locales  y demás disposiciones aplicables en la materia.<br />
  Las UA llevarán a cabo las  acciones pertinentes para garantizar el efectivo ejercicio de los Derechos ARCO  de los titulares, acorde con los principios, obligaciones y deberes en materia  de protección de datos personales. </p>
<p>&nbsp;</p>
<p><strong>3. GUÍA PARA EL EJERCICIO       DE LOS DERECHOS ARCO DE LA COMISIÓN DE TRANSPARENCIA Y ACCESO A LA       INFORMACIÓN DEL ESTADO DE NUEVO LEÓN </strong></p>
<p><a href="http://www.asenl.gob.mx/transparencia/43/GUIA_PARA_EJERCER_LOS_DERECHOS_ARCO.pdf" target="_blank" class="btn btn-secondary" rel="noreferrer" >Descargar Guía</a>  </p>
<p>&nbsp;</p>
<p><strong>4. PROCEDIMIENTO  PARA RECIBIR Y RESPONDER DUDAS Y QUEJAS DE LOS TITULARES DE DATOS PERSONALES</strong></p>
        <p>La ASENL recibirá y contestará  las dudas y quejas que presenten los titulares de datos personales, de  conformidad al artículo 35, fracción VI y numeral 46 de la Ley de Protección de  Datos Personales en Posesión de Sujetos Obligados del Estado de Nuevo León, de  acuerdo al siguiente </p>
        <p><strong>PROCEDIMIENTO</strong></p>
        <p>a) Las dudas y quejas que  presenten los titulares de datos personales podrán ser anónimas y se  garantizará la confidencialidad de la información presentada y de su  utilización.</p>
        <p>b) Las dudas y quejas podrán  presentarse tanto por medios físicos como electrónicos, para lo cual la ASENL  cuenta con los mecanismos necesarios a efecto de agilizar el trámite  correspondiente. </p>
        <p>c) La ASENL llevará un registro  de todas las dudas y quejas recibidas. El procedimiento para la recepción y  respuesta de las dudas y quejas que formulen los titulares de datos personales  deberá estar habilitado en todo momento.</p>
        <p>d) El medio electrónico para  recibir y contestar dudas y quejas será a través del correo de la Unidad de  Transparencia de la ASENL <a href="mailto:accesoainformacion@asenl.gob.mx">accesoainformacion@asenl.gob.mx</a> y el medio físico en el domicilio de la ASENL.</p>
        <p>e) Toda duda o queja merece  atención, independientemente de que resulte justificada o no, y siempre deberá  recaer a las mismas una respuesta rápida y expedita. </p>
        <p>f) La respuesta debe estar  personalizada: dirigida a un ciudadano en concreto. Debe tratar todas y cada  una de las cuestiones tratadas en la queja o duda, se debe de utilizar un  lenguaje claro, evitar tecnicismos innecesarios. </p>
        <p>g) Las dudas podrán formularse en  escrito libre, que exprese de forma clara el cuestionamiento sobre el tema  relacionado con los datos personales. </p>
        <p>Cuando se reciba por medio  electrónico se contestará de la misma manera.</p>
        <p>h) Los requisitos para presentar  una queja:</p>
        <ol>
          <li>Llenar el formato el cual se encuentra  físicamente instalado en el portal oficial de internet de la ASENL; </li>
          <li>Presentar escrito libre que contenga la  narración de los hechos en forma clara y breve precisando las circunstancias de  modo, tiempo y lugar, o bien, datos o indicios que permitan establecer una  investigación. </li>
        </ol>
        <p>Cuando no se reúnan los  requisitos señalados o no se aporten datos o indicios mínimos para llevar a  cabo la investigación a efecto de que proceda la queja, se archivará el  expediente como concluido. </p>
i) &nbsp;Una  vez recibida la queja, se procederá a remitir al Comité de Transparencia y  Unidad de Transparencia. El Comité de Transparencia y la Unidad Transparencia  deberán emitir un Acuerdo para determinar si la queja es procedente o no, lo  cual será notificado al quejoso.
<p class="style401">
</p><p><a href="http://www.asenl.gob.mx/transparencia/43/formulario-de-quejas_asenl.pdf" target="_blank"  rel="noreferrer" class="btn btn-secondary">Formato quejas Titulares de Datos Personales</a>  </p>
<p>&nbsp;</p>
      </td></tr></tbody></table>      </Col>
      </Row>
    </Container>
    </>
  );
};

export default Proteccion;