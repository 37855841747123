import React, {useState} from 'react';
import { Container, Row, Col, Modal, Button, Table, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { articles } from '../../data/Articulo106_Json';

const Articulo106 = () => {
  const [show, setShow] = useState(false);
  const [article, setArticle] = useState(null);
  const handleClose = () => setShow(false);

  const handleOpenArticle = (item) => {
    setArticle(item);
    setShow(true);
  }

  return (<>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title className='text-center'>{article?.Fraction}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Row><Col className='pb-4'>{article?.Description} </Col></Row>
          {article?.Files && 
          <Row>
            <Col className='pb-2'>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th className='text-center'>Archivo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {article?.Files?.map((file, index) => (
                  <tr key={index}>
                    <td>{file.Name}</td>
                    <td className='text-center path'><Link to={`../assets/documents/transparencia/articulo106/${file.Path}`} target="_blank" download><FontAwesomeIcon icon={faFileExport} /></Link></td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          }
          {article?.Files2 && 
          <Row>
            <Col className='pb-2'>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th className='text-center'>Archivo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {article?.Files2?.map((file, index) => (
                  <tr key={index}>
                    <td>{file.Name}</td>
                    <td className='text-center path'><Link to={`../assets/documents/transparencia/articulo95/${file.Path}`} target="_blank" download><FontAwesomeIcon icon={faFileExport} /></Link></td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          }
          <Row>
            <Col className='text-small'>
              Área Responsable<br />
              {article?.Area}<br />
              Actualizado al {article?.Update}<br />
              Período de actualización: {article?.Period}<br />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

    <Container className='pt-4 pb-4 fadeIn'>
      <Row><Col className='text-center pb-3 article-title'><h1>Artículo 106</h1></Col></Row>
      <Row><Col className='pb-3'>Para determinar la información adicional que publicarán todos los sujetos obligados de manera obligatoria, la Comisión deberá:</Col></Row>
      
      <Row>
         {articles.map((item, index) => (
          <Col key={item.FractionId} xs={12} md={4} className='mb-2 ps-3 article d-flex align-items-center' onClick={() => handleOpenArticle(item)}>
            <span><Image src={`../assets/img/iconos/106/${item.Icon}`} className='article-img'/></span>
            <span>{item.Fraction} - {item.Title}</span>
          </Col>
          ))}
      </Row>
    </Container>
    </>
  );
};

export default Articulo106;