import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const Header = () => {
  return (
    <header className="bg-White pt-3 pb-3">
      <Container className="image-container fluid">
        <Row>
          <Col xs={6} md={6}><Image src="./assets/img/logos/Logo.png" className="logo" alt=""/></Col>
          <Col xs={6} md={6} className="d-flex justify-content-end"><Image src="./assets/img/logos/circulos.png" className="escudo" alt=""/></Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
 