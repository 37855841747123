import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import Articulo95 from './pages/transparencia/Articulo95';
import Articulo98 from './pages/transparencia/Articulo98';
import Articulo106 from './pages/transparencia/Articulo106';
import Articulo147 from './pages/transparencia/Articulo147';
import Articulo167 from './pages/transparencia/Articulo167';
import Informacion from './pages/transparencia/Informacion';
import Solicitudes from './pages/transparencia/Solicitudes';
import Proteccion from './pages/transparencia/Proteccion';
import Contacto from './pages/Contacto';


 
const App = () => {
  return (
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/transparencia/articulo95" element={<Articulo95/>} />
            <Route path="/transparencia/articulo98" element={<Articulo98/>} />
            <Route path="/transparencia/articulo106" element={<Articulo106/>} />
            <Route path="/transparencia/articulo147" element={<Articulo147/>} />
            <Route path="/transparencia/articulo167" element={<Articulo167/>} />
            <Route path="/transparencia/informacion" element={<Informacion/>} />
            <Route path="/transparencia/solicitudes" element={<Solicitudes/>} />
            <Route path="/transparencia/proteccion" element={<Proteccion/>} />
            <Route path="/contacto" element={<Contacto />} />

            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </Router>
  );
};

export default App;
