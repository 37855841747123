export const articles =[
    { 
      FractionId: 1, 
      Fraction: "Fracción I", 
      Title: "Marco normativo", 
      Area: "Unidad de Asuntos Jurídicos (Analista Juridico)", 
      Update: "30/11/2023", 
      Period: "Mensual",
      Description: "El marco normativo aplicable al sujeto obligado, en el que deberá incluirse leyes, códigos, reglamentos, decretos de creación, manuales administrativos, reglas de operación, criterios, políticas, entre otros.",  
      Icon: "I_blanco80.png",
      Files:[{Name: "Marco Normativo", Path: "I_Marco_Normativo_Octubre2023.xlsx"}]
    },

    { 
      FractionId: 2, 
      Fraction: "Fracción II", 
      Title: " Estructura orgánica", 
      Area: "Unidad General de Administración (Jefatura de Recursos Humanos)", 
      Update: "30/11/2023", 
      Period: "Mensual",
      Description: "Su estructura orgánica completa, en un formato que permita vincular cada parte de la estructura, las atribuciones y responsabilidades que le corresponden a cada servidor público, prestador de servicios profesionales o miembro de los sujetos obligados, de conformidad con las disposiciones aplicable.",  
      Icon: "II_blanco80.png",
      Files:[
        {Name: "Estructura Orgánica (A)", Path: "IIA_Estructura_Octubre2023.xlsx"},
        {Name: "Estructura Orgánica (B)", Path: "IIB_Estructura_Octubre2023.xlsx"}
      ]
    },
     
    { FractionId: 3, 
      Fraction: "Fracción III", 
      Title: "Facultades de cada Área", 
      Area: "Unidad General de Administración (Jefatura de Recursos Humanos)",
      Update: "30/11/2023", 
      Period: "Mensual",
      Description: "Las facultades de cada Área.",  
      Icon: "III_blanco80.png",
      Files:[{Name: "Facultades de las áreas", Path: "III_facultades_area_Octubre2023.xlsx"}]
    },

    { 
      FractionId: 4, 
      Fraction: "Fracción IV", 
      Title: "Áreas de conformidad",
      Area: "Unidad General de Administración (Jefatura de Capital Humano y Profesionalización)", 
      Update: "23/02/2022", 
      Period: "Anual (en su caso, 30 días naturales una vez generada la información)",
      Icon: "IV_blanco80.png",
      Description: "Las metas y objetivos de las Áreas de conformidad con sus programas operativos",
      Files:[
        {Name: "Metas y Objetivos 2023", Path: "IV_Metas_objetivos_Anual2023.xlsx"},
        {Name: "Metas y Objetivos 2022", Path: "IV_Metas_objetivos_Anual2022.xlsx"},
        {Name: "Metas y Objetivos 2020", Path: "IV_Metas_objetivos_Anual2020.xlsx"},
        {Name: "Metas y Objetivos 2019", Path: "IV_Metas_objetivos_Anual2019.xlsx"},
        {Name: "Metas y Objetivos 2018", Path: "IV_Metas_objetivos_Anual2018.xlsx"},
        {Name: "Metas y Objetivos 2017", Path: "IV_Metas_objetivos_Anual2017.xlsx"},
      ]  
    },

    { 
      FractionId: 5, 
      Fraction: "Fracción V", 
      Title: "Indicadores interés público",
      Area: "Dirección de Programas y Procesos", 
      Update: "20/12/2023", 
      Period: "Mensual",
      Icon: "V_blanco80.png",
      Description: "Los indicadores relacionados con temas de interés público o trascendencia social que conforme a sus funciones, deban establecer. (F. DE E. P.O. 02 DE NOVIEMBRE DE 2016)",  
      Files:[
        {Name: "Noviembre 2023", Path: "V_indicadores_interes_publico_Noviembre2023.xlsx"},
        {Name: "Octubre 2023", Path: "V_indicadores_interes_publico_Octubre2023.xlsx"},
        {Name: "Septiembre 2023", Path: "V_indicadores_interes_publico_Septiembre2023.xlsx"},
        {Name: "Agosto 2023", Path: "V_indicadores_interes_publico_Agosto2023.xlsx"},
        {Name: "Julio 2023", Path: "V_indicadores_interes_publico_Julio2023.xlsx"},
        {Name: "Junio 2023", Path: "V_indicadores_interes_publico_Junio2023.xlsx"},
        {Name: "Mayo 2023", Path: "V_indicadores_interes_publico_Mayo2023.xlsx"},
        {Name: "Abril 2023", Path: "V_indicadores_interes_publico_Abril2023.xlsx"},
        {Name: "Marzo 2023", Path: "V_indicadores_interes_publico_Marzo2023.xlsx"},
        {Name: "Febrero 2023", Path: "V_indicadores_interes_publico_Febrero2023.xlsx"},
        {Name: "Enero 2023", Path: "V_indicadores_interes_publico_Enero2023.xlsx"},

        {Name: "Diciembre 2022", Path: "V_indicadores_interes_publico_Diciembre2022.xlsx"},
        {Name: "Noviembre 2022", Path: "V_indicadores_interes_publico_Noviembre2022.xlsx"},
        {Name: "Octubre 2022", Path: "V_indicadores_interes_publico_Octubre2022.xlsx"},
        {Name: "Septiembre 2022", Path: "V_indicadores_interes_publico_Septiembre2022.xlsx"},
        {Name: "Agosto 2022", Path: "V_indicadores_interes_publico_Agosto2022.xlsx"},
        {Name: "Julio 2022", Path: "V_indicadores_interes_publico_Julio2022.xlsx"},
        {Name: "Junio 2022", Path: "V_indicadores_interes_publico_Junio2022.xlsx"},
        {Name: "Mayo 2022", Path: "V_indicadores_interes_publico_Mayo2022.xlsx"},
        {Name: "Abril 2022", Path: "V_indicadores_interes_publico_Abril2022.xlsx"},
        {Name: "Marzo 2022", Path: "V_indicadores_interes_publico_Marzo2022.xlsx"},
        {Name: "Febrero 2022", Path: "V_indicadores_interes_publico_Febrero2022.xlsx"},
        {Name: "Enero 2022", Path: "V_indicadores_interes_publico_Enero2022.xlsx"},

        {Name: "Diciembre 2021", Path: "V_indicadores_interes_publico_Diciembre2021.xlsx"},
        {Name: "Noviembre 2021", Path: "V_indicadores_interes_publico_Noviembre2021.xlsx"},
        {Name: "Octubre 2021", Path: "V_indicadores_interes_publico_Octubre2021.xlsx"},
        {Name: "Septiembre 2021", Path: "V_indicadores_interes_publico_Septiembre2021.xlsx"},
        {Name: "Agosto 2021", Path: "V_indicadores_interes_publico_Agosto2021.xlsx"},
        {Name: "Julio 2021", Path: "V_indicadores_interes_publico_Julio2021.xlsx"},
        {Name: "Junio 2021", Path: "V_indicadores_interes_publico_Junio2021.xlsx"},
        {Name: "Mayo 2021", Path: "V_indicadores_interes_publico_Mayo2021.xlsx"},
        {Name: "Abril 2021", Path: "V_indicadores_interes_publico_Abril2021.xlsx"},
        {Name: "Marzo 2021", Path: "V_indicadores_interes_publico_Marzo2021.xlsx"},
        {Name: "Febrero 2021", Path: "V_indicadores_interes_publico_Febrero2021.xlsx"},
        {Name: "Enero 2021", Path: "V_indicadores_interes_publico_Enero2021.xlsx"},

        {Name: "Diciembre 2020", Path: "V_indicadores_interes_publico_Diciembre2020.xlsx"},
        {Name: "Noviembre 2020", Path: "V_indicadores_interes_publico_Noviembre2020.xlsx"},
        {Name: "Octubre 2020", Path: "V_indicadores_interes_publico_Octubre2020.xlsx"},
        {Name: "Septiembre 2020", Path: "V_indicadores_interes_publico_Septiembre2020.xlsx"},
        {Name: "Agosto 2020", Path: "V_indicadores_interes_publico_Agosto2020.xlsx"},
        {Name: "Julio 2020", Path: "V_indicadores_interes_publico_Julio2020.xlsx"},
        {Name: "Junio 2020", Path: "V_indicadores_interes_publico_Junio2020.xlsx"},
        {Name: "Mayo 2020", Path: "V_indicadores_interes_publico_Mayo2020.xlsx"},
        {Name: "Abril 2020", Path: "V_indicadores_interes_publico_Abril2020.xlsx"},
        {Name: "Marzo 2020", Path: "V_indicadores_interes_publico_Marzo2020.xlsx"},
        {Name: "Febrero 2020", Path: "V_indicadores_interes_publico_Febrero2020.xlsx"},
        {Name: "Enero 2020", Path: "V_indicadores_interes_publico_Enero2020.xlsx"},

        {Name: "Diciembre 2019", Path: "V_indicadores_interes_publico_Diciembre2019.xlsx"},
        {Name: "Noviembre 2019", Path: "V_indicadores_interes_publico_Noviembre2019.xlsx"},
        {Name: "Octubre 2019", Path: "V_indicadores_interes_publico_Octubre2019.xlsx"},
        {Name: "Septiembre 2019", Path: "V_indicadores_interes_publico_Septiembre2019.xlsx"},
        {Name: "Agosto 2019", Path: "V_indicadores_interes_publico_Agosto2019.xlsx"},
        {Name: "Julio 2019", Path: "V_indicadores_interes_publico_Julio2019.xlsx"},
        {Name: "Junio 2019", Path: "V_indicadores_interes_publico_Junio2019.xlsx"},
        {Name: "Mayo 2019", Path: "V_indicadores_interes_publico_Mayo2019.xlsx"},
        {Name: "Abril 2019", Path: "V_indicadores_interes_publico_Abril2019.xlsx"},
        {Name: "Marzo 2019", Path: "V_indicadores_interes_publico_Marzo2019.xlsx"},
        {Name: "Febrero 2019", Path: "V_indicadores_interes_publico_Febrero2019.xlsx"},
        {Name: "Enero 2019", Path: "V_indicadores_interes_publico_Enero2019.xlsx"},

        {Name: "Diciembre 2018", Path: "V_indicadores_interes_publico_Diciembre2018.xlsx"},
        {Name: "Noviembre 2018", Path: "V_indicadores_interes_publico_Noviembre2018.xlsx"},
        {Name: "Octubre 2018", Path: "V_indicadores_interes_publico_Octubre2018.xlsx"},
        {Name: "Tercer Trimestre Enero a Marzo 2018", Path: "V_indicadores_interes_publico_TerTrim2018.xlsx"},
        {Name: "Segundo Trimestre Enero a Marzo 2018", Path: "V_indicadores_interes_publico_SegTrim2018.xlsx"},
        {Name: "Primer Trimestre Enero a Marzo 2018", Path: "V_indicadores_interes_publico_PrimTrim2018.xlsx"},
      ]
    },

    { 
      FractionId: 6, 
      Fraction: "Fracción VI", 
      Title: "Indicadores que permitan rendir cuenta",      
      Area: "Dirección de Programas y Procesos", 
      Update: "20/12/2023", 
      Period: "Mensual",
      Icon: "VI_blanco80.png",
      Description: "Los indicadores que permitan rendir cuenta de sus objetivos y resultados. (F. DE E. P.O. 02 DE NOVIEMBRE DE 2016)",  
      Files:[
        {Name: "Noviembre 2023", Path: "VI_Indicadores_objetivos_Noviembre2023.xlsx"},
        {Name: "Octubre 2023", Path: "VI_Indicadores_objetivos_Octubre2023.xlsx"},
        {Name: "Septiembre 2023", Path: "VI_Indicadores_objetivos_Septiembre2023.xlsx"},
        {Name: "Agosto 2023", Path: "VI_Indicadores_objetivos_Agosto2023.xlsx"},
        {Name: "Julio 2023", Path: "VI_Indicadores_objetivos_Julio2023.xlsx"},
        {Name: "Junio 2023", Path: "VI_Indicadores_objetivos_Junio2023.xlsx"},
        {Name: "Mayo 2023", Path: "VI_Indicadores_objetivos_Mayo2023.xlsx"},
        {Name: "Abril 2023", Path: "VI_Indicadores_objetivos_Abril2023.xlsx"},
        {Name: "Marzo 2023", Path: "VI_Indicadores_objetivos_Marzo2023.xlsx"},
        {Name: "Febrero 2023", Path: "VI_Indicadores_objetivos_Febrero2023.xlsx"},
        {Name: "Enero 2023", Path: "VI_Indicadores_objetivos_Enero2023.xlsx"},

        {Name: "Diciembre 2022", Path: "VI_Indicadores_objetivos_Diciembre2022.xlsx"},
        {Name: "Noviembre 2022", Path: "VI_Indicadores_objetivos_Noviembre2022.xlsx"},
        {Name: "Octubre 2022", Path: "VI_Indicadores_objetivos_Octubre2022.xlsx"},
        {Name: "Septiembre 2022", Path: "VI_Indicadores_objetivos_Septiembre2022.xlsx"},
        {Name: "Agosto 2022", Path: "VI_Indicadores_objetivos_Agosto2022.xlsx"},
        {Name: "Julio 2022", Path: "VI_Indicadores_objetivos_Julio2022.xlsx"},
        {Name: "Junio 2022", Path: "VI_Indicadores_objetivos_Junio2022.xlsx"},
        {Name: "Mayo 2022", Path: "VI_Indicadores_objetivos_Mayo2022.xlsx"},
        {Name: "Abril 2022", Path: "VI_Indicadores_objetivos_Abril2022.xlsx"},
        {Name: "Marzo 2022", Path: "VI_Indicadores_objetivos_Marzo2022.xlsx"},
        {Name: "Febrero 2022", Path: "VI_Indicadores_objetivos_Febrero2022.xlsx"},
        {Name: "Enero 2022", Path: "VI_Indicadores_objetivos_Enero2022.xlsx"},

        {Name: "Diciembre 2021", Path: "VI_Indicadores_objetivos_Diciembre2021.xlsx"},
        {Name: "Noviembre 2021", Path: "VI_Indicadores_objetivos_Noviembre2021.xlsx"},
        {Name: "Octubre 2021", Path: "VI_Indicadores_objetivos_Octubre2021.xlsx"},
        {Name: "Septiembre 2021", Path: "VI_Indicadores_objetivos_Septiembre2021.xlsx"},
        {Name: "Agosto 2021", Path: "VI_Indicadores_objetivos_Agosto2021.xlsx"},
        {Name: "Julio 2021", Path: "VI_Indicadores_objetivos_Julio2021.xlsx"},
        {Name: "Junio 2021", Path: "VI_Indicadores_objetivos_Junio2021.xlsx"},
        {Name: "Mayo 2021", Path: "VI_Indicadores_objetivos_Mayo2021.xlsx"},
        {Name: "Abril 2021", Path: "VI_Indicadores_objetivos_Abril2021.xlsx"},
        {Name: "Marzo 2021", Path: "VI_Indicadores_objetivos_Marzo2021.xlsx"},
        {Name: "Febrero 2021", Path: "VI_Indicadores_objetivos_Febrero2021.xlsx"},
        {Name: "Enero 2021", Path: "VI_Indicadores_objetivos_Enero2021.xlsx"},

        {Name: "Diciembre 2020", Path: "VI_Indicadores_objetivos_Diciembre2020.xlsx"},
        {Name: "Noviembre 2020", Path: "VI_Indicadores_objetivos_Noviembre2020.xlsx"},
        {Name: "Octubre 2020", Path: "VI_Indicadores_objetivos_Octubre2020.xlsx"},
        {Name: "Septiembre 2020", Path: "VI_Indicadores_objetivos_Septiembre2020.xlsx"},
        {Name: "Agosto 2020", Path: "VI_Indicadores_objetivos_Agosto2020.xlsx"},
        {Name: "Julio 2020", Path: "VI_Indicadores_objetivos_Julio2020.xlsx"},
        {Name: "Junio 2020", Path: "VI_Indicadores_objetivos_Junio2020.xlsx"},
        {Name: "Mayo 2020", Path: "VI_Indicadores_objetivos_Mayo2020.xlsx"},
        {Name: "Abril 2020", Path: "VI_Indicadores_objetivos_Abril2020.xlsx"},
        {Name: "Marzo 2020", Path: "VI_Indicadores_objetivos_Marzo2020.xlsx"},
        {Name: "Febrero 2020", Path: "VI_Indicadores_objetivos_Febrero2020.xlsx"},
        {Name: "Enero 2020", Path: "VI_Indicadores_objetivos_Enero2020.xlsx"},

        {Name: "Diciembre 2019", Path: "VI_Indicadores_objetivos_Diciembre2019.xlsx"},
        {Name: "Noviembre 2019", Path: "VI_Indicadores_objetivos_Noviembre2019.xlsx"},
        {Name: "Octubre 2019", Path: "VI_Indicadores_objetivos_Octubre2019.xlsx"},
        {Name: "Septiembre 2019", Path: "VI_Indicadores_objetivos_Septiembre2019.xlsx"},
        {Name: "Agosto 2019", Path: "VI_Indicadores_objetivos_Agosto2019.xlsx"},
        {Name: "Julio 2019", Path: "VI_Indicadores_objetivos_Julio2019.xlsx"},
        {Name: "Junio 2019", Path: "VI_Indicadores_objetivos_Junio2019.xlsx"},
        {Name: "Mayo 2019", Path: "VI_Indicadores_objetivos_Mayo2019.xlsx"},
        {Name: "Abril 2019", Path: "VI_Indicadores_objetivos_Abril2019.xlsx"},
        {Name: "Marzo 2019", Path: "VI_Indicadores_objetivos_Marzo2019.xlsx"},
        {Name: "Febrero 2019", Path: "VI_Indicadores_objetivos_Febrero2019.xlsx"},
        {Name: "Enero 2019", Path: "VI_Indicadores_objetivos_Enero2019.xlsx"},

        {Name: "Diciembre 2018", Path: "VI_Indicadores_objetivos_Diciembre2018.xlsx"},
        {Name: "Noviembre 2018", Path: "VI_Indicadores_objetivos_Noviembre2018.xlsx"},
        {Name: "Octubre 2018", Path: "VI_Indicadores_objetivos_Octubre2018.xlsx"},
        {Name: "Tercer Trimestre Enero a Marzo 2018", Path: "VI_Indicadores_objetivos_TerTrim2018.xlsx"},
        {Name: "Segundo Trimestre Enero a Marzo 2018", Path: "VI_Indicadores_objetivos_SegTrim2018.xlsx"},
        {Name: "Primer Trimestre Enero a Marzo 2018", Path: "VI_Indicadores_objetivos_PrimTrim2018.xlsx"},
      ]
    },
    { 
      FractionId: 7, 
      Fraction: "Fracción VII", 
      Title: "Indicadores estratégicos y de gestión",
      Area: "Dirección de Programas y Procesos", 
      Update: "20/12/2023", 
      Period: "Mensual",
      Description: "Los indicadores estratégicos y de gestión, así como los resultados obtenidos en las evaluaciones del desempeño que se realicen a través de la verificación del grado de cumplimiento de sus objetivos y metas.",  
      Icon: "VII_blanco80.png",
      Files:[
        {Name: "Noviembre 2023", Path: "VII_Indicadores_estrategicos_Noviembre2023.xlsx"},
        {Name: "Octubre 2023", Path: "VII_Indicadores_estrategicos_Octubre2023.xlsx"},
        {Name: "Septiembre 2023", Path: "VII_Indicadores_estrategicos_Septiembre2023.xlsx"},
        {Name: "Agosto 2023", Path: "VII_Indicadores_estrategicos_Agosto2023.xlsx"},
        {Name: "Julio 2023", Path: "VII_Indicadores_estrategicos_Julio2023.xlsx"},
        {Name: "Junio 2023", Path: "VII_Indicadores_estrategicos_Junio2023.xlsx"},
        {Name: "Mayo 2023", Path: "VII_Indicadores_estrategicos_Mayo2023.xlsx"},
        {Name: "Abril 2023", Path: "VII_Indicadores_estrategicos_Abril2023.xlsx"},
        {Name: "Marzo 2023", Path: "VII_Indicadores_estrategicos_Marzo2023.xlsx"},
        {Name: "Febrero 2023", Path: "VII_Indicadores_estrategicos_Febrero2023.xlsx"},
        {Name: "Enero 2023", Path: "VII_Indicadores_estrategicos_Enero2023.xlsx"},

        {Name: "Diciembre 2022", Path: "VII_Indicadores_estrategicos_Diciembre2022.xlsx"},
        {Name: "Noviembre 2022", Path: "VII_Indicadores_estrategicos_Noviembre2022.xlsx"},
        {Name: "Octubre 2022", Path: "VII_Indicadores_estrategicos_Octubre2022.xlsx"},
        {Name: "Septiembre 2022", Path: "VII_Indicadores_estrategicos_Septiembre2022.xlsx"},
        {Name: "Agosto 2022", Path: "VII_Indicadores_estrategicos_Agosto2022.xlsx"},
        {Name: "Julio 2022", Path: "VII_Indicadores_estrategicos_Julio2022.xlsx"},
        {Name: "Junio 2022", Path: "VII_Indicadores_estrategicos_Junio2022.xlsx"},
        {Name: "Mayo 2022", Path: "VII_Indicadores_estrategicos_Mayo2022.xlsx"},
        {Name: "Abril 2022", Path: "VII_Indicadores_estrategicos_Abril2022.xlsx"},
        {Name: "Marzo 2022", Path: "VII_Indicadores_estrategicos_Marzo2022.xlsx"},
        {Name: "Febrero 2022", Path: "VII_Indicadores_estrategicos_Febrero2022.xlsx"},
        {Name: "Enero 2022", Path: "VII_Indicadores_estrategicos_Enero2022.xlsx"},
      ]
    }, 

    { 
      FractionId: 8, 
      Fraction: "Fracción VIII", 
      Title: "Directorio de todos los Servidores Públicos",
      Area: "Unidad General de Administración (Jefatura de Recursos Humanos)", 
      Update: "30/11/2023", 
      Period: "Mensual",
      Description: "El directorio de todos los Servidores Públicos, a partir del nivel de jefe de departamento o su equivalente, o de menor nivel, cuando se brinde atención al público; manejen o apliquen recursos públicos; realicen actos de autoridad o presten servicios profesionales bajo el régimen de confianza u honorarios y personal de base. El directorio deberá incluir, al menos el nombre, cargo o nombramiento asignado, nivel del puesto en la estructura orgánica, fecha de alta en el cargo, número telefónico, domicilio para recibir correspondencia y dirección de correo electrónico oficiales.",  
      Icon: "VIII_blanco80.png",
      Files:[{Name: "Directorio", Path: "VIII_Directorio_servidores_publicos_Octubre2023.xlsx"}]
    }, 

    { 
      FractionId: 9, 
      Fraction: "Fracción IX", 
      Title: "Remuneración bruta y neta",
      Area: "Unidad General de Administración (Jefatura de Nóminas y Prestaciones)", 
      Update: "20/12/2023", 
      Period: "Mensual",
      Icon: "IX_blanco80.png",
      Files:[
        {Name: "Nómina - Noviembre 2023", Path: "IX_Noviembre2023_NominaA.xlsx"},
        {Name: "Nómina - Octubre 2023", Path: "IX_Octubre2023_NominaA.xlsx"},
        {Name: "Nómina - Septiembre 2023", Path: "IX_Septiembre2023_NominaA.xlsx"},
        {Name: "Nómina - Agosto 2023", Path: "IX_Agosto2023_NominaA.xlsx"},
        {Name: "Nómina - Julio 2023", Path: "IX_Julio2023_NominaA.xlsx"},
        {Name: "Nómina - Junio 2023", Path: "IX_Junio2023_NominaA.xlsx"},
        {Name: "Nómina - Mayo 2023", Path: "IX_Mayo2023_NominaA.xlsx"},
        {Name: "Nómina - Abril 2023", Path: "IX_Abril2023_NominaA.xlsx"},
        {Name: "Nómina - Marzo 2023", Path: "IX_Marzo2023_NominaA.xlsx"},
        {Name: "Nómina - Febrero 2023", Path: "IX_Febrero2023_NominaA.xlsx"},
        {Name: "Nómina - Enero 2023", Path: "IX_Enero2023_NominaA.xlsx"},

        {Name: "Nómina - Diciembre 2022", Path: "IX_Diciembre2022_NominaA.xlsx"},
        {Name: "Nómina - Noviembre 2022", Path: "IX_Noviembre2022_NominaA.xlsx"},
        {Name: "Nómina - Octubre 2022", Path: "IX_Octubre2022_NominaA.xlsx"},
        {Name: "Nómina - Septiembre 2022", Path: "IX_Septiembre2022_NominaA.xlsx"},
        {Name: "Nómina - Agosto 2022", Path: "IX_Agosto2022_NominaA.xlsx"},
        {Name: "Nómina - Julio 2022", Path: "IX_Julio2022_NominaA.xlsx"},
        {Name: "Nómina - Junio 2022", Path: "IX_Junio2022_NominaA.xlsx"},
        {Name: "Nómina - Mayo 2022", Path: "IX_Mayo2022_NominaA.xlsx"},
        {Name: "Nómina - Abril 2022", Path: "IX_Abril2022_NominaA.xlsx"},
        {Name: "Nómina - Marzo 2022", Path: "IX_Marzo2022_NominaA.xlsx"},
        {Name: "Nómina - Febrero 2022", Path: "IX_Febrero2022_NominaA.xlsx"},
        {Name: "Nómina - Enero 2022", Path: "IX_Enero2022_NominaA.xlsx"},

        {Name: "Nómina - Diciembre 2021", Path: "IX_Diciembre2021_NominaA.xlsx"},
        {Name: "Nómina - Noviembre 2021", Path: "IX_Noviembre2021_NominaA.xlsx"},
        {Name: "Nómina - Octubre 2021", Path: "IX_Octubre2021_NominaA.xlsx"},
        {Name: "Nómina - Septiembre 2021", Path: "IX_Septiembre2021_NominaA.xlsx"},
        {Name: "Nómina - Agosto 2021", Path: "IX_Agosto2021_NominaA.xlsx"},
        {Name: "Nómina - Julio 2021", Path: "IX_Julio2021_NominaA.xlsx"},
        {Name: "Nómina - Junio 2021", Path: "IX_Junio2021_NominaA.xlsx"},
        {Name: "Nómina - Mayo 2021", Path: "IX_Mayo2021_NominaA.xlsx"},
        {Name: "Nómina - Abril 2021", Path: "IX_Abril2021_NominaA.xlsx"},
        {Name: "Nómina - Marzo 2021", Path: "IX_Marzo2021_NominaA.xlsx"},
        {Name: "Nómina - Febrero 2021", Path: "IX_Febrero2021_NominaA.xlsx"},
        {Name: "Nómina - Enero 2021", Path: "IX_Enero2021_NominaA.xlsx"},

        {Name: "Nómina - Diciembre 2020", Path: "IX_Diciembre2020_Nomina.xlsx"},
        {Name: "Nómina - Noviembre 2020", Path: "IX_Noviembre2020_Nomina.xlsx"},
        {Name: "Nómina - Octubre 2020", Path: "IX_Octubre2020_Nomina.xlsx"},
        {Name: "Nómina - Septiembre 2020", Path: "IX_Septiembre2020_Nomina.xlsx"},
        {Name: "Nómina - Agosto 2020", Path: "IX_Agosto2020_Nomina.xlsx"},
        {Name: "Nómina - Julio 2020", Path: "IX_Julio2020_Nomina.xlsx"},
        {Name: "Nómina - Junio 2020", Path: "IX_Junio2020_Nomina.xlsx"},
        {Name: "Nómina - Mayo 2020", Path: "IX_Mayo2020_Nomina.xlsx"},
        {Name: "Nómina - Abril 2020", Path: "IX_Abril2020_Nomina.xlsx"},
        {Name: "Nómina - Marzo 2020", Path: "IX_Marzo2020_Nomina.xlsx"},
        {Name: "Nómina - Febrero 2020", Path: "IX_Febrero2020_Nomina.xlsx"},
        {Name: "Nómina - Enero 2020", Path: "IX_Enero2020_Nomina.xlsx"},

        {Name: "Nómina - Diciembre 2019", Path: "IX_Diciembre2019_Nomina.xlsx"},
        {Name: "Nómina - Noviembre 2019", Path: "IX_Noviembre2019_Nomina.xlsx"},
        {Name: "Nómina - Octubre 2019", Path: "IX_Octubre2019_Nomina.xlsx"},
        {Name: "Nómina - Septiembre 2019", Path: "IX_Septiembre2019_Nomina.xlsx"},
        {Name: "Nómina - Agosto 2019", Path: "IX_Agosto2019_Nomina.xlsx"},
        {Name: "Nómina - Julio 2019", Path: "IX_Julio2019_Nomina.xlsx"},
        {Name: "Nómina - Junio 2019", Path: "IX_Junio2019_Nomina.xlsx"},
        {Name: "Nómina - Mayo 2019", Path: "IX_Mayo2019_Nomina.xlsx"},
        {Name: "Nómina - Abril 2019", Path: "IX_Abril2019_Nomina.xlsx"},
        {Name: "Nómina - Marzo 2019", Path: "IX_Marzo2019_Nomina.xlsx"},
        {Name: "Nómina - Febrero 2019", Path: "IX_Febrero2019_Nomina.xlsx"},
        {Name: "Nómina - Enero 2019", Path: "IX_Enero2019_Nomina.xlsx"},

        {Name: "Nómina - Diciembre 2018", Path: "IX_Diciembre2018_Nomina.xlsx"},
        {Name: "Nómina - Noviembre 2018", Path: "IX_Noviembre2018_Nomina.xlsx"},
        {Name: "Nómina - Octubre 2018", Path: "IX_Octubre2018_Nomina.xlsx"},
        {Name: "Nómina - Segundo Trimestre 2018", Path: "IX_SegSem2018_Nomina.xlsx"},
        {Name: "Nómina - Primer Trimestre 2018", Path: "IX_PrimSem2018_Nomina.xlsx"},

        {Name: "Nómina - Diciembre 2017", Path: "IX_Diciembre2017_Nomina.xls"},
        {Name: "Nómina - Noviembre 2017", Path: "IX_Noviembre2017_Nomina.xls"},
        {Name: "Nómina - Octubre 2017", Path: "IX_Octubre2017_Nomina.xls"},
        {Name: "Nómina - Septiembre 2017", Path: "IX_Septiembre2017_Nomina.xls"},
        {Name: "Nómina - Agosto 2017", Path: "IX_Agosto2017_Nomina.xls"},
        {Name: "Nómina - Julio 2017", Path: "IX_Julio2017_Nomina.xls"},
        {Name: "Nómina - Junio 2017", Path: "IX_Junio2017_Nomina.xls"},
        {Name: "Nómina - Mayo 2017", Path: "IX_Mayo2017_Nomina.xls"},
        {Name: "Nómina - Abril 2017", Path: "IX_Abril2017_Nomina.xls"},
        {Name: "Nómina - Marzo 2017", Path: "IX_Marzo2017_Nomina.xls"},
        {Name: "Nómina - Febrero 2017", Path: "IX_Febrero2017_Nomina.xls"},
        {Name: "Nómina - Enero 2017", Path: "IX_Enero2017_Nomina.xls"},

        {Name: "Nómina - Diciembre 2016", Path: "IX_Diciembre2016_Nomina.xls"},
        {Name: "Nómina - Noviembre 2016", Path: "IX_Noviembre2016_Nomina.xls"},
        {Name: "Nómina - Octubre 2016", Path: "IX_Octubre2016_Nomina.xls"},
        {Name: "Nómina - Septiembre 2016", Path: "IX_Septiembre2016_Nomina.xls"},
        {Name: "Nómina - Agosto 2016", Path: "IX_Agosto2016_Nomina.xls"},
        {Name: "Nómina - Julio 2016", Path: "IX_Julio2016_Nomina.xls"},
        {Name: "Nómina - Junio 2016", Path: "IX_Junio2016_Nomina.xls"},
        {Name: "Nómina - Mayo 2016", Path: "IX_Mayo2016_Nomina.xls"},
        {Name: "Nómina - Abril 2016", Path: "IX_Abril2016_Nomina.xls"},
        {Name: "Nómina - Marzo 2016", Path: "IX_Marzo2016_Nomina.xls"},
        {Name: "Nómina - Febrero 2016", Path: "IX_Febrero2016_Nomina.xls"},
        {Name: "Nómina - Enero 2016", Path: "IX_Enero2016_Nomina.xls"}
      ],
      Files2:[
        {Name: "Tabulador de Sueldos - Noviembre 2023", Path: "IX_Noviembre2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Octubre 2023", Path: "IX_Octubre2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Septiembre 2023", Path: "IX_Septiembre2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Agosto 2023", Path: "IX_Agosto2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Julio 2023", Path: "IX_Julio2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Junio 2023", Path: "IX_Junio2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Mayo 2023", Path: "IX_Mayo2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Abril 2023", Path: "IX_Abril2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Marzo 2023", Path: "IX_Marzo2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Febrero 2023", Path: "IX_Febrero2023_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Enero 2023", Path: "IX_Enero2023_NominaB.xlsx"},

        {Name: "Tabulador de Sueldos - Diciembre 2022", Path: "IX_Diciembre2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Noviembre 2022", Path: "IX_Noviembre2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Octubre 2022", Path: "IX_Octubre2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Septiembre 2022", Path: "IX_Septiembre2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Agosto 2022", Path: "IX_Agosto2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Julio 2022", Path: "IX_Julio2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Junio 2022", Path: "IX_Junio2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Mayo 2022", Path: "IX_Mayo2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Abril 2022", Path: "IX_Abril2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Marzo 2022", Path: "IX_Marzo2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Febrero 2022", Path: "IX_Febrero2022_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Enero 2022", Path: "IX_Enero2022_NominaB.xlsx"},

        {Name: "Tabulador de Sueldos - Diciembre 2021", Path: "IX_Diciembre2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Noviembre 2021", Path: "IX_Noviembre2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Octubre 2021", Path: "IX_Octubre2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Septiembre 2021", Path: "IX_Septiembre2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Agosto 2021", Path: "IX_Agosto2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Julio 2021", Path: "IX_Julio2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Junio 2021", Path: "IX_Junio2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Mayo 2021", Path: "IX_Mayo2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Abril 2021", Path: "IX_Abril2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Marzo 2021", Path: "IX_Marzo2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Febrero 2021", Path: "IX_Febrero2021_NominaB.xlsx"},
        {Name: "Tabulador de Sueldos - Enero 2021", Path: "IX_Enero2021_NominaB.xlsx"}
      ]
    },

    { FractionId: 10, 
      Fraction: "Fracción X", 
      Title: "Gastos de representación y viáticos",
      Area: "Unidad General de Administración (Jefatura de Recursos Financieros y Contables)", 
      Update: "20/12/2023", 
      Period: "Mensual (fracción B N/A para el ejercicio 2018)",
      Description: "Los gastos de representación y viáticos, así como el objeto e informe de comisión correspondiente, donde se vinculen estos gastos mensuales al servidor público que los ejecutó con motivo de su encargo o comisión.",
      Icon: "X_blanco80.png",
      Files:[
        {Name: "Noviembre 2023", Path: "X_Noviembre2023_Viaticos.xlsx"},
        {Name: "Octubre 2023", Path: "X_Octubre2023_Viaticos.xlsx"},
        {Name: "Septiembre 2023", Path: "X_Septiembre2023_Viaticos.xlsx"},
        {Name: "Agosto 2023", Path: "X_Agosto2023_Viaticos.xlsx"},
        {Name: "Julio 2023", Path: "X_Julio2023_Viaticos.xlsx"},
        {Name: "Junio 2023", Path: "X_Junio2023_Viaticos.xlsx"},
        {Name: "Mayo 2023", Path: "X_Mayo2023_Viaticos.xlsx"},
        {Name: "Abril 2023", Path: "X_Abril2023_Viaticos.xlsx"},
        {Name: "Marzo 2023", Path: "X_Marzo2023_Viaticos.xlsx"},
        {Name: "Febrero 2023", Path: "X_Febrero2023_Viaticos.xlsx"},
        {Name: "Enero 2023", Path: "X_Enero2023_Viaticos.xlsx"},

        {Name: "Diciembre 2022", Path: "X_Diciembre2022_Viaticos.xlsx"},
        {Name: "Noviembre 2022", Path: "X_Noviembre2022_Viaticos.xlsx"},
        {Name: "Octubre 2022", Path: "X_Octubre2022_Viaticos.xlsx"},
        {Name: "Septiembre 2022", Path: "X_Septiembre2022_Viaticos.xlsx"},
        {Name: "Agosto 2022", Path: "X_Agosto2022_Viaticos.xlsx"},
        {Name: "Julio 2022", Path: "X_Julio2022_Viaticos.xlsx"},
        {Name: "Junio 2022", Path: "X_Junio2022_Viaticos.xlsx"},
        {Name: "Mayo 2022", Path: "X_Mayo2022_Viaticos.xlsx"},
        {Name: "Abril 2022", Path: "X_Abril2022_Viaticos.xlsx"},
        {Name: "Marzo 2022", Path: "X_Marzo2022_Viaticos.xlsx"},
        {Name: "Febrero 2022", Path: "X_Febrero2022_Viaticos.xlsx"},
        {Name: "Enero 2022", Path: "X_Enero2022_Viaticos.xlsx"},

        {Name: "Diciembre 2021", Path: "X_Diciembre2021_Viaticos.xlsx"},
        {Name: "Noviembre 2021", Path: "X_Noviembre2021_Viaticos.xlsx"},
        {Name: "Octubre 2021", Path: "X_Octubre2021_Viaticos.xlsx"},
        {Name: "Septiembre 2021", Path: "X_Septiembre2021_Viaticos.xlsx"},
        {Name: "Agosto 2021", Path: "X_Agosto2021_Viaticos.xlsx"},
        {Name: "Julio 2021", Path: "X_Julio2021_Viaticos.xlsx"},
        {Name: "Junio 2021", Path: "X_Junio2021_Viaticos.xlsx"},
        {Name: "Mayo 2021", Path: "X_Mayo2021_Viaticos.xlsx"},
        {Name: "Abril 2021", Path: "X_Abril2021_Viaticos.xlsx"},
        {Name: "Marzo 2021", Path: "X_Marzo2021_Viaticos.xlsx"},
        {Name: "Febrero 2021", Path: "X_Febrero2021_Viaticos.xlsx"},
        {Name: "Enero 2021", Path: "X_Enero2021_Viaticos.xlsx"},

        {Name: "Diciembre 2020", Path: "X_Diciembre2020_Viaticos.xlsx"},
        {Name: "Noviembre 2020", Path: "X_Noviembre2020_Viaticos.xlsx"},
        {Name: "Octubre 2020", Path: "X_Octubre2020_Viaticos.xlsx"},
        {Name: "Septiembre 2020", Path: "X_Septiembre2020_Viaticos.xlsx"},
        {Name: "Agosto 2020", Path: "X_Agosto2020_Viaticos.xlsx"},
        {Name: "Julio 2020", Path: "X_Julio2020_Viaticos.xlsx"},
        {Name: "Junio 2020", Path: "X_Junio2020_Viaticos.xlsx"},
        {Name: "Mayo 2020", Path: "X_Mayo2020_Viaticos.xlsx"},
        {Name: "Abril 2020", Path: "X_Abril2020_Viaticos.xlsx"},
        {Name: "Marzo 2020", Path: "X_Marzo2020_Viaticos.xlsx"},
        {Name: "Febrero 2020", Path: "X_Febrero2020_Viaticos.xlsx"},
        {Name: "Enero 2020", Path: "X_Enero2020_Viaticos.xlsx"},

        {Name: "Diciembre 2019", Path: "X_Diciembre2019_Viaticos.xlsx"},
        {Name: "Noviembre 2019", Path: "X_Noviembre2019_Viaticos.xlsx"},
        {Name: "Octubre 2019", Path: "X_Octubre2019_Viaticos.xlsx"},
        {Name: "Septiembre 2019", Path: "X_Septiembre2019_Viaticos.xlsx"},
        {Name: "Agosto 2019", Path: "X_Agosto2019_Viaticos.xlsx"},
        {Name: "Julio 2019", Path: "X_Julio2019_Viaticos.xlsx"},
        {Name: "Junio 2019", Path: "X_Junio2019_Viaticos.xlsx"},
        {Name: "Mayo 2019", Path: "X_Mayo2019_Viaticos.xlsx"},
        {Name: "Abril 2019", Path: "X_Abril2019_Viaticos.xlsx"},
        {Name: "Marzo 2019", Path: "X_Marzo2019_Viaticos.xlsx"},
        {Name: "Febrero 2019", Path: "X_Febrero2019_Viaticos.xlsx"},
        {Name: "Enero 2019", Path: "X_Enero2019_Viaticos.xlsx"},

        {Name: "Diciembre 2018", Path: "X_Diciembre2018_Viaticos.xlsx"},
        {Name: "Noviembre 2018", Path: "X_Noviembre2018_Viaticos.xlsx"},
        {Name: "Octubre 2018", Path: "X_Octubre2018_Viaticos.xlsx"},
        {Name: "Tercer Trimestre 2018", Path: "X_TerTrim2018_Viaticos.xlsx"},
        {Name: "Segundo Trimestre 2018", Path: "X_SegTrim2018_Viaticos.xlsx"},
        {Name: "Primer Trimestre 2018", Path: "X_PrimTrim2018_Viaticos.xlsx"},

        {Name: "Diciembre 2017", Path: "X_Diciembre2017_Viaticos.xls"},
        {Name: "Noviembre 2017", Path: "X_Noviembre2017_Viaticos.xls"},
        {Name: "Octubre 2017", Path: "X_Octubre2017_Viaticos.xls"},
        {Name: "Septiembre 2017", Path: "X_Septiembre2017_Viaticos.xls"},
        {Name: "Agosto 2017", Path: "X_Agosto2017_Viaticos.xls"},
        {Name: "Julio 2017", Path: "X_Julio2017_Viaticos.xls"},
        {Name: "Junio 2017", Path: "X_Junio2017_Viaticos.xls"},
        {Name: "Mayo 2017", Path: "X_Mayo2017_Viaticos.xlsx"},
        {Name: "Abril 2017", Path: "X_Abril2017_Viaticos.xlsx"},
        {Name: "Marzo 2017", Path: "X_Marzo2017_Viaticos.xlsx"},
        {Name: "Febrero 2017", Path: "X_Febrero2017_Viaticos.xls"},
        {Name: "Enero 2017", Path: "X_Enero2017_Viaticos.xls"},

        {Name: "Diciembre 2016", Path: "X_Diciembre2016_Viaticos.xls"},
        {Name: "Noviembre 2016", Path: "X_Noviembre2016_Viaticos.xls"},
        {Name: "Octubre 2016", Path: "X_Octubre2016_Viaticos.xlsx"},
        {Name: "Septiembre 2016", Path: "X_Septiembre2016_Viaticos.xls"},
        {Name: "Agosto 2016", Path: "X_Agosto2016_Viaticos.xls"},
        {Name: "Julio 2016", Path: "X_Julio2016_Viaticos.xls"},
        {Name: "Junio 2016", Path: "X_Junio2016_Viaticos.xls"},
        {Name: "Mayo 2016", Path: "X_Mayo2016_Viaticos.xls"},
        {Name: "Abril 2016", Path: "X_Abril2016_Viaticos.xlsx"},
        {Name: "Marzo 2016", Path: "X_Marzo2016_Viaticos.xlsx"},
        {Name: "Febrero 2016", Path: "X_Febrero2016_Viaticos.xlsx"},
        {Name: "Enero 2016", Path: "X_Enero2016_Viaticos.xls"}
      ],
  
    }, 
    { FractionId: 11, Fraction: "Fracción XI", Title: "Número total de las plazas", Icon: "XI_blanco80.png" }, 
    { FractionId: 12, Fraction: "Fracción XII", Title: "Contrataciones de servicios profesionales", Icon: "XII_blanco80.png" }, 
    { FractionId: 13, Fraction: "Fracción XIII", Title: "Información de las declaraciones patrimoniales", Icon: "XIII_blanco80.png" },
    { FractionId: 14, Fraction: "Fracción XIV", Title: "Domicilio de la Unidad de Transparencia", Icon: "XIV_blanco80.png" }, 
    { FractionId: 15, Fraction: "Fracción XV", Title: "Convocatorias a concursos", Icon: "XV_blanco80.png" },
    { FractionId: 16, Fraction: "Fracción XVI", Title: "Programas de subsidios, estímulos y apoyos", Icon: "XVI_blanco80.png" },
    { FractionId: 17, Fraction: "Fracción XVII", Title: "Condiciones generales de trabajo, contratos o convenios", Icon: "XVII_blanco80.png" },
    { FractionId: 18, Fraction: "Fracción XVIII", Title: "Información curricular", Icon: "XVIII_blanco80.png" },
    { FractionId: 19, Fraction: "Fracción XIX", Title: "Servidores Públicos con sanciones administrativas", Icon: "XIX_blanco80.png" },
    { FractionId: 20, Fraction: "Fracción XX", Title: "Servicios que ofrecen", Icon: "XX_blanco80.png" },
    { FractionId: 21, Fraction: "Fracción XXI", Title: "Trámites, con sus requisitos, formatos, plazos y costos", Icon: "XXI_blanco80.png" },
    { FractionId: 22, Fraction: "Fracción XXII", Title: "Últimos 5 ejercicios fiscales", Icon: "XXII_blanco80.png" },
    { FractionId: 23, Fraction: "Fracción XXIII", Title: "Información relativa a la deuda pública", Icon: "XXIII_blanco80.png" },
    { FractionId: 24, Fraction: "Fracción XXIV", Title: "Montos destinados a gastos relativos", Icon: "XXIV_blanco80.png" },
    { FractionId: 25, Fraction: "Fracción XXV", Title: "Informes de resultados de las auditorías al ejercicio presupuesta", Icon: "XXV_blanco80.png" },
    { FractionId: 26, Fraction: "Fracción XXVI", Title: "Resultado de la dictaminación de los estados financieros", Icon: "XXVI_blanco80.png" },
    { FractionId: 27, Fraction: "Fracción XXVII", Title: "Montos, criterios, convocatorias", Icon: "XXVII_blanco80.png" },
    { FractionId: 28, Fraction: "Fracción XXVIII", Title: "Concesiones, contratos, convenios, permisos, licencias o autorizaciones ", Icon: "XXVIII_blanco80.png" },
    { FractionId: 29, Fraction: "Fracción XXIX", Title: "Información sobre los resultados sobre procedimientos", Icon: "XXIX_blanco80.png" },
    { FractionId: 30, Fraction: "Fracción XXX", Title: "Informes que por disposición legal generen los sujetos obligados", Icon: "XXX_blanco80.png" },
    { FractionId: 31, Fraction: "Fracción XXXI", Title: "Estadísticas que generen en cumplimiento de sus facultades", Icon: "XXXI_blanco80.png" },
    { FractionId: 32, Fraction: "Fracción XXXII", Title: "Informe de avances programáticos o presupuestales", Icon: "XXXII_blanco80.png" },
    { FractionId: 33, Fraction: "Fracción XXXIII", Title: "Padrón de proveedores y contratistas", Icon: "XXXIII_blanco80.png" },
    { FractionId: 34, Fraction: "Fracción XXXIV", Title: "Convenios de coordinación con la Federación, Estados, Municipios", Icon: "XXXIV_blanco80.png" },
    { FractionId: 35, Fraction: "Fracción XXXV", Title: "Inventario de bienes muebles e inmuebles en posesión y propiedad", Icon: "XXXV_blanco80.png" },
    { FractionId: 36, Fraction: "Fracción XXXVI", Title: "FRecomendaciones emitidas por los órganos públicos del Estado mexicano", Icon: "XXXVI_blanco80.png" },
    { FractionId: 37, Fraction: "Fracción XXXVII", Title: "Resoluciones y laudos que se emitan en procesos o procedimientos", Icon: "XXXVII_blanco80.png" },
    { FractionId: 38, Fraction: "Fracción XXXVIII", Title: "Los mecanismos de participación ciudadana", Icon: "XXXVIII_blanco80.png" },
    { FractionId: 39, Fraction: "Fracción XXXIX", Title: "Programas que ofrecen", Icon: "XXXIX_blanco80.png" },
    { FractionId: 40, Fraction: "Fracción XL", Title: "Actas y resoluciones del Comité de Transparencia", Icon: "XL_blanco80.png" },
    { FractionId: 41, Fraction: "Fracción XLI", Title: "Evaluaciones y encuestas", Icon: "XLI_blanco80.png" },
    { FractionId: 42, Fraction: "Fracción XLII", Title: "Estudios financiados con recursos públicos", Icon: "XLII_blanco80.png" },
    { FractionId: 43, Fraction: "Fracción XLIII", Title: "Listado de jubilados y pensionados y el monto que reciben", Icon: "XLIII_blanco80.png" },
    { FractionId: 44, Fraction: "Fracción XLIV", Title: "Ingresos recibidos por cualquier concepto", Icon: "XLIV_blanco80.png" },
    { FractionId: 45, Fraction: "Fracción XLV", Title: "Donaciones hechas a terceros en dinero o en especie", Icon: "XLV_blanco80.png" },
    { FractionId: 46, Fraction: "Fracción XLVI", Title: "Catálogo de disposición y guía de archivo documenta", Icon: "XLVI_blanco80.png" },
    { FractionId: 47, Fraction: "Fracción XLVII", Title: "Actas de sesiones ordinarias y extraordinarias públicas y privada", Icon: "XLVII_blanco80.png" },
    { FractionId: 48, Fraction: "Fracción XLVIII", Title: "Contratos de asociación público privada", Icon: "XLVIII_blanco80.png" },
    { FractionId: 49, Fraction: "Fracción XLIX", Title: "Relación de los servidores públicos comisionados por cualquier causa", Icon: "XLIX_blanco80.png" },
    { FractionId: 50, Fraction: "Fracción L", Title: "Listado de los programas de capacitación", Icon: "L_blanco80.png" },
    { FractionId: 51, Fraction: "Fracción LI", Title: "Resultados de las evaluaciones de desempeño de los servidores públicos", Icon: "LI_blanco80.png" },
    { FractionId: 52, Fraction: "Fracción LII", Title: "Listado de las acciones de inconstitucionalidad y controversias constitucionales ", Icon: "LII_blanco80.png" },
    { FractionId: 53, Fraction: "Fracción LIII", Title: "Información relativa al objeto, erogación, destino, justificación", Icon: "LIII_blanco80.png" },
    { FractionId: 54, Fraction: "Fracción LIV", Title: "Información que sea de utilidad o se considere relevante", Icon: "LIV_blanco80.png" },
    { FractionId: 55, Fraction: "Fracción LV", Title: "Rubros aplicables a la página de internet", Icon: "LV_blanco80.png" }
  ];