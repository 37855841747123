import React, {useState} from 'react';
import { Container, Row, Col, Image, Card, Modal } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import ListGroup from 'react-bootstrap/ListGroup'; 

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const handleItemClick = (index) => {
    setActiveIndex(index)
  };

  return (<>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className='center' style={{ minHeight: '380px', maxHeight: '600px', minWidth: '480px'}}>
        <Row>
          <Col className='pb-4 text-center'><Image src="./assets/img/esquela.jpg" className="esquela" alt=""/></Col>
         </Row>
      </Modal.Body>
    </Modal>

    <Container fluid className='fadeIn' style={{background:"#26292b"}}>
      <Container>
        <Row>
          <Col xs={12} md={9} className='ps-0 pe-0'>
            <Carousel fade controls={false} indicators={false} activeIndex={activeIndex}>
              <Carousel.Item interval={3000}>
                {/* <ExampleCarouselImage text="First slide" /> */}
                <Image src="./assets/img/edificio.jpg" width={"110%"}/>
                <Carousel.Caption>
                <Card className="transparent-card">
                  <Card.Body className="transparent-card-body">
                    <Card.Title className="transparent-card-title"><b>AUDITORÍA SUPERIOR DEL ESTADO DE NUEVO LEÓN</b></Card.Title>
                    <Card.Text className="transparent-card-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                {/* <ExampleCarouselImage text="Second slide" /> */}
                <Image src="./assets/img/iso9001.jpg" width={"110%"}/>
                <Carousel.Caption>
                <Card className="transparent-card">
                  <Card.Body className="transparent-card-body">
                    <Card.Title className="transparent-card-title"><b>SISTEMA DE GESTIÓN DE CALIDAD DE LA ASENL ISO 9001:2015</b></Card.Title>
                    <Card.Text className="transparent-card-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
              {/*  <ExampleCarouselImage text="Third slide" /> */}
              <Image src="./assets/img/27.jpg" width={"110%"}/>
                <Carousel.Caption>
                <Card className="transparent-card">
                  <Card.Body className="transparent-card-body">
                    <Card.Title className="transparent-card-title"><b>PROCEDIMIENTO ADMINISTRATIVO DE RESPONSABILIDAD EN EL SISTEMA NACIONAL ANTICORRUPCIÓN USAID</b></Card.Title>
                    <Card.Text className="transparent-card-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>

          <Col xs={12} md={3} className='ps-0 pe-0'>
            <ListGroup as="ol" className="home-scrollable-list home-list-group">
              <ListGroup.Item action as="li" onClick={() => handleItemClick(0)} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src="./assets/img/edificio.jpg"/></span>
                <span>AUDITORÍA SUPERIOR DEL ESTADO DE NUEVO LEÓN<br/><span className="home-item-date">29 de Noviembre 2023</span></span>       
              </ListGroup.Item>
              <ListGroup.Item action as="li" onClick={() => handleItemClick(1)} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src="./assets/img/iso9001.jpg"/></span>
                <span>SISTEMA DE GESTIÓN DE CALIDAD DE LA ASENL ISO 9001:2015<br/><span className="home-item-date">29 de Noviembre 2023</span></span>
              </ListGroup.Item>
              <ListGroup.Item action as="li" onClick={() => handleItemClick(2)} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src="./assets/img/27.jpg"/></span>
                <span>PROCEDIMIENTO ADMINISTRATIVO DE RESPONSABILIDAD EN EL SISTEMA NACIONAL ANTICORRUPCIÓN USAID<br/><span className="home-item-date">29 de Noviembre 2023</span></span>
              </ListGroup.Item>
              <ListGroup.Item action as="li" onClick={() => handleItemClick(0)} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src="./assets/img/edificio.jpg"/></span>
                <span>AUDITORÍA SUPERIOR DEL ESTADO DE NUEVO LEÓN<br/><span className="home-item-date">29 de Noviembre 2023</span></span>
              </ListGroup.Item>
              <ListGroup.Item action as="li" onClick={() => handleItemClick(1)} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src="./assets/img/iso9001.jpg"/></span>
                <span>SISTEMA DE GESTIÓN DE CALIDAD DE LA ASENL ISO 9001:2015<br/><span className="home-item-date">29 de Noviembre 2023</span></span>
              </ListGroup.Item>
              <ListGroup.Item action as="li" onClick={() => handleItemClick(2)} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src="./assets/img/27.jpg"/></span>
                <span>PROCEDIMIENTO ADMINISTRATIVO DE RESPONSABILIDAD EN EL SISTEMA NACIONAL ANTICORRUPCIÓN USAID<br/><span className="home-item-date">29 de Noviembre 2023</span></span>
              </ListGroup.Item>
              <ListGroup.Item action as="li" onClick={() => handleItemClick(0)} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src="./assets/img/edificio.jpg"/></span>
                <span>AUDITORÍA SUPERIOR DEL ESTADO DE NUEVO LEÓN<br/><span className="home-item-date">29 de Noviembre 2023</span></span>
              </ListGroup.Item>
              <ListGroup.Item action as="li" onClick={() => handleItemClick(1)} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src="./assets/img/iso9001.jpg"/></span>
                <span>SISTEMA DE GESTIÓN DE CALIDAD DE LA ASENL ISO 9001:2015<br/><span className="home-item-date">29 de Noviembre 2023</span></span>
              </ListGroup.Item>
              <ListGroup.Item action as="li" onClick={() => handleItemClick(2)} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src="./assets/img/27.jpg"/></span>
                <span>PROCEDIMIENTO ADMINISTRATIVO DE RESPONSABILIDAD EN EL SISTEMA NACIONAL ANTICORRUPCIÓN USAID<br/><span className="home-item-date">29 de Noviembre 2023</span></span>
              </ListGroup.Item>
            </ListGroup> 
          </Col>
        </Row>
      </Container>
    </Container>

    <Container className="mt-3 mb-3">
      <Row className="ms-4 me-4">
        <Col xs={12} md={12} className='text-center bg-wine text-white p-4 font-raleway'>
          <h3><b>Publicación de Decretos conforme al artículo 90 párrafo sexto de la Constitución Política del Estado Libre y Soberano de Nuevo León</b></h3>
        </Col>
      </Row>
    </Container>
    <Container fluid className="p-0">
      <iframe border="0" title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.885524984699!2d-100.35053728546015!3d25.675089218447344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866295ffb6229ef3%3A0xc02215c95d1f0c81!2sAuditoria+Superior+del+Estado+de+Nuevo+Le%C3%B3n!5e0!3m2!1ses!2smx!4v1453427351471" width="100%" height="250"  style={{"border": 0}}></iframe>
    </Container>
    </>
 );
};

export default Home;