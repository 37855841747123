import React, {useState} from 'react';
import { Container, Row, Col, Modal, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { articles } from '../../data/Articulo147_Json';

const Articulo147 = () => {
  const [show, setShow] = useState(false);
  const [article, setArticle] = useState(null);
  const handleClose = () => setShow(false);

  const handleOpenArticle = (item) => {
    setArticle(item);
    setShow(true);
  }

  return (<>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title className='text-center'>{article?.Fraction}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Row><Col className='pb-4'>{article?.Description} </Col></Row>
          {article?.Files && 
          <Row>
            <Col className='pb-2'>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th className='text-center'>Archivo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {article?.Files?.map((file, index) => (
                  <tr key={index}>
                    <td>{file.Name}</td>
                    <td className='text-center path'><Link to={`../assets/documents/transparencia/articulo106/${file.Path}`} target="_blank" download><FontAwesomeIcon icon={faFileExport} /></Link></td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          }
          {article?.Files2 && 
          <Row>
            <Col className='pb-2'>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th className='text-center'>Archivo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {article?.Files2?.map((file, index) => (
                  <tr key={index}>
                    <td>{file.Name}</td>
                    <td className='text-center path'><Link to={`../assets/documents/transparencia/articulo95/${file.Path}`} target="_blank" download><FontAwesomeIcon icon={faFileExport} /></Link></td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          }
          <Row>
            <Col className='text-small'>
              Área Responsable<br />
              {article?.Area}<br />
              Actualizado al {article?.Update}<br />
              Período de actualización: {article?.Period}<br />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

    <Container className='pt-4 pb-4 fadeIn'>
      <Row><Col className='text-center pb-3 article-title'><h1>Artículo 147</h1></Col></Row>
      <Row><Col className='pb-3'>Solicitudes de Acceso a la Información</Col></Row>
      <Row><Col className='pb-3'>Cualquier persona por sí misma o a través de su representante, podrá presentar solicitud de acceso a información ante la Unidad de Transparencia, a través de la Plataforma Nacional de Transparencia, en la oficina u oficinas designadas para ello, vía correo electrónico, correo postal, mensajería, telégrafo, verbalmente o cualquier medio aprobado por el Sistema Nacional.</Col></Row>
      <Row><Col className='text-center pb-3 article-title'>INFOMEX NL</Col></Row>

      <Row>
        <Col className='pb-3'>
            <p>El Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales informa que derivado de la puesta en marcha de la Plataforma Nacional de Transparencia, el pasado cinco de mayo, los Sistemas INFOMEX o similares de los Estados y de la Federación continuarán operando durante el proceso de transición al nuevo esquema informativo nacional; por lo que mediante estos Sistemas podrán realizarse solicitudes de información y en su caso, interponer recursos de revisión.</p>
            <p>Lo anterior, con fundamento en el artículo décimo tercero de los Lineamientos para la Implementación y Operación de la Plataforma Nacional de Transparencia, publicados en el Diario Oficial de la Federación el cuatro de mayo de dos mil dieciséis.</p>
            <p>Está a tu disposición el Tel (018002CTAINL) para asesorarte sobre tu derecho de acceso a la información.</p>
            <p>De clic aquí para que lo dirija a las dirección electrónica del INFOMEX Nuevo León <a href="http://nl.infomex.org.mx/" target='_blank' rel="noreferrer">http://nl.infomex.org.mx/</a></p>
        </Col>
      </Row>
      <Row>
         {articles.map((item, index) => (
          <Col key={item.FractionId} xs={12} md={4} className='mb-2 ps-3 article d-flex align-items-center' onClick={() => handleOpenArticle(item)}>
            <span>{item.Fraction} - {item.Title}</span>
          </Col>
          ))}
      </Row>
    </Container>
    </>
  );
};

export default Articulo147;