import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Informacion = () => {
  return (<>
   
    <Container className='pt-4 pb-4 fadeIn'>
      <Row><Col className='text-center pb-3 article-title'><h1>Catálogo de Información Pública</h1></Col></Row>  
      <Row>
      <table class="table table-striped">
        <thead>
          <tr>
              <th>Descripción</th>
            <th width="129">Hipervínculo a la información</th>
          </tr>
        </thead><tbody>
          <tr class="active">
          
            <td>Solicitudes de Información</td>
            <td><p><a href="http://www.asenl.gob.mx/transparencia/solicitudes/" target="”_blank”">http://www.asenl.gob.mx/transparencia/solicitudes/</a></p></td>
          </tr>
          <tr class="active">
        
          
            <td>Informes del Resultado</td>
            <td><p><a href="http://www.asenl.gob.mx/cta_publica/municipios/2020/2020.html" target="”_blank”">http://www.asenl.gob.mx/cta_publica/municipios/2020/2020.html</a></p>
            <p><a href="http://www.asenl.gob.mx/cta_publica/poder_ejecutivo/2020/2020.html" target="”_blank”">http://www.asenl.gob.mx/cta_publica/poder_ejecutivo/2020/2020.html</a></p>
            <p><a href="http://www.asenl.gob.mx/cta_publica/legislativo/2020/2020.html" target="”_blank”">http://www.asenl.gob.mx/cta_publica/legislativo/2020/2020.html</a></p>
            <p><a href="http://www.asenl.gob.mx/cta_publica/judicial/2020/2020.html" target="”_blank”">http://www.asenl.gob.mx/cta_publica/judicial/2020/2020.html</a></p>
            <p><a href="http://www.asenl.gob.mx/cta_publica/organismos/2020/2020.html" target="”_blank”">http://www.asenl.gob.mx/cta_publica/organismos/2020/2020.html</a></p>
          </td></tr>
          <tr class="active">
            <td>Preguntas Frecuentes</td>
            <td><p><a href="http://www.asenl.gob.mx/identidad/preguntas_frecuentes/" target="”_blank”">http://www.asenl.gob.mx/identidad/preguntas_frecuentes/</a></p></td>
          </tr>
          <tr class="active">
            <td>“Diagnóstico”, en el cual la Auditoría Superior del Estado de Nuevo León, llevó a cabo un recorrido por los diferentes espacios, identificando las áreas de oportunidad que se deben adaptar para ofrecer espacios con las condiciones de accesibilidad adecuadas para hacer uso de los mismos en igualdad de condiciones y garantizar a los grupos vulnerables el ejercicio de sus derechos humanos de Acceso a la Información y Protección de Datos Personales.</td>
            <td><p><a href="http://www.asenl.gob.mx/transparencia/95/pdf/LIII/DIAG_ASENL.pdf" target="”_blank”">http://www.asenl.gob.mx/transparencia/95/pdf/LIII/DIAG_ASENL.pdf</a></p></td>
          </tr>
          <tr class="active">
            <td>“Plan de Accesibilidad”, en el cual la Auditoría Superior del Estado de Nuevo León, llevó a cabo un proyecto en base al resultado del Diagnóstico, Identificando las áreas de oportunidad que se deben adaptar para ofrecer espacios con las condiciones de accesibilidad adecuadas para hacer uso de los mismos en igualdad de condiciones y garantizar a los grupos vulnerables el ejercicio de sus derechos humanos de Acceso a la Información y Protección de Datos Personales.</td>
            <td> <a href="http://www.asenl.gob.mx/transparencia/95/pdf/LIII/PLAN_ACCESIBILIDAD_ASENL.pdf" target="”_blank”">http://www.asenl.gob.mx/transparencia/95/pdf/LIII/PLAN_ACCESIBILIDAD_ASENL.pdf</a></td>
          </tr>
        </tbody>

      </table>
      </Row>
    </Container>
    </>
  );
};

export default Informacion;